import Header from "../components/header"
import Login from "./login"
import React from "react"
import TransApp from "./transapp"
import firebase from "gatsby-plugin-firebase"
import { navigate } from "gatsby"

class IndexPage extends React.Component {
  constructor() {
    super()

    this.state = {
      loggedIn: false,
    }
  }

  componentDidMount() {
    console.log("running")
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        console.log("Logged In " + user.email)
        this.setState({ loggedIn: true })
        // navigate("/transapp");
      } else {
        this.setState({ loggedIn: false })
      }
    })
  }

  render() {
    if (this.state.loggedIn === false) {
      return (
        <div>
          <Login />
        </div>
      )
    } else if (this.state.loggedIn === true) {
      return (
        <div>
          <TransApp />
        </div>
      )
    }
  }
}

export default IndexPage
